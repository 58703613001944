<template>
  <static-fullscreen-card :loaded="loaded">
    <template v-slot:title v-if="loaded"> ({{ data.id }}) {{ data.name }} [{{ statusCurrent.text }}] </template>
    <template v-slot:actions v-if="loaded">
      <v-btn-toggle
        v-if="getAccess('object.showPlanning') || getAccess('object.showStatistic')"
        v-model="objectTypeView"
        mandatory
        borderless
        dense
        class="v-btn"
        @change="changeTab(objectTypeView, 'type')"
      >
        <v-btn value="tabObjectView-main" color="#d0bf9c" class="ml-0">
          <span class="hidden-lg-and-down">Общий</span>
          <v-icon :right="$vuetify.breakpoint.lgAndUp"> fas fa-wrench </v-icon>
        </v-btn>
        <v-btn value="tabObjectView-stat" class="ml-1" color="#d0bf9c">
          <span class="hidden-lg-and-down">Планирование</span>
          <v-icon :right="$vuetify.breakpoint.lgAndUp"> fas fa-chart-bar </v-icon>
        </v-btn>
      </v-btn-toggle>

      <a-btn-status v-if="statusObject" :items="statusItems" @click="clickStatus($event)" :disabled="loading" />
      <a-btn-edit v-if="showObject && $root.profile.role > 10" :title="editObject ? 'Редактирование' : 'Просмотр'" @click="showEditDialog = true" />
      <a-btn-edit v-if="$root.profile.role == 10001" @click="oldDoc = !oldDoc" :title="'docs'" />
    </template>
    <div v-if="loaded">
      <portal to="v-goup" v-if="idShow">
        <v-btn class="mr-3" fab small @click="$emit('close', data)">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </portal>
      <portal to="v-main">
        <edit-dialog v-model="showEditDialog" :id="id" :api="url" v-if="showObject" />
        <StateSaleDialog v-model="showStateSaleDialog" :id="id" :object="JSON.parse(JSON.stringify(data))" />
        <s-quick-addDocument v-if="showAddDocDialog" v-model="showAddDocDialog" :id="id" :api="url" :modelDoc="addDocModel" />
      </portal>
      <v-tabs-items v-model="objectTypeView" class="transparent" touchless>
        <v-tab-item :id="`tabObjectView-main`">
          <v-row v-if="!loading">
            <v-col cols="12" sm="6">
              <material-card color="secondary">
                <template v-slot:heading>
                  <v-tabs v-model="tab_photo" background-color="transparent" slider-color="white">
                    <v-tab class="mr-3">
                      {{ "Фото объекта" }}
                    </v-tab>
                    <v-tab class="mr-3">
                      {{ "Дизайн-проект" }}
                    </v-tab>
                  </v-tabs>
                </template>
                <v-tabs-items v-model="tab_photo" class="transparent" style="min-height: 200px" touchless>
                  <v-tab-item>
                    <a-view-images :value="data.photos" :style="{ height: blockHeight + 'px' }" />
                  </v-tab-item>
                  <v-tab-item>
                    <a-view-images :value="data.photos_design" :style="{ height: blockHeight + 'px' }" />
                  </v-tab-item>
                </v-tabs-items>
              </material-card>
            </v-col>
            <v-col cols="12" sm="6">
              <material-card color="secondary">
                <template v-slot:heading1>
                  <div class="px-3 py-1">основная информация</div>
                </template>
                <template v-slot:heading>
                  <v-tabs v-model="tab_info" background-color="transparent" slider-color="white">
                    <v-tab v-if="tab.show" class="mr-3" v-for="tab in infoTabs" :key="`tab-${tab.name}`">
                      {{ tab.title }}
                    </v-tab>
                  </v-tabs>
                </template>
                <v-tabs-items v-model="tab_info" class="transparent" style="min-height: 200px" touchless>
                  <v-tab-item v-if="tab.show" v-for="tab in infoTabs" :key="`tab-${tab.name}`">
                    <a-form-view
                      v-if="tab.name == 'info'"
                      :value="data"
                      :model="modelRight"
                      :model1="[oModel['investor_id'], oModel['client_id'], model[0], model[1], model[2], model[3], model[4], model[12]]"
                      :config="{ dense: true, hideNull: true }"
                      class="block_master"
                    />
                    <a-form-view
                      v-if="tab.name == 'finance'"
                      :value="data"
                      :model="[
                        { name: 'investment_status', value: investmentText, title: 'Объем финансирования', type: 'string', showNull: true },
                        $root.profile.role == 10 ? {} : { name: 'finance_status', value: balanceText, title: 'Финансовый статус', type: 'string', showNull: true },
                      ]"
                      :config="{ dense: true, hideNull: true }"
                      class="block_master"
                    />
                    <TabGoodsSummary v-if="tab.name == 'goods'" :id="data.id" />
                  </v-tab-item>
                </v-tabs-items>
              </material-card>
            </v-col>
            <v-col cols="12" v-if="showObject && !loading">
              <material-card color="third">
                <template v-slot:heading>
                  <v-tabs dense v-model="tab" background-color="transparent" slider-color1="white" show-arrows center-active @change="changeTab(tab)">
                    <v-tab v-for="(tab, i) in tabs" :key="i" v-if="showTab(tab)" :href="`#tab-${tab.name}`" class="mr-3">
                      {{ tab.title }}
                      <v-icon v-if="tab.icon && showObject" @click.native.prevent.stop="clickTabIcon(tab)">{{ tab.icon }}</v-icon>
                    </v-tab>
                  </v-tabs>
                </template>
                <v-tabs-items v-if="loaded" v-model="tab" class="transparent" style="min-height: 200px" touchless>
                  <v-tab-item v-for="(tab, i) in tabs" :key="i" v-if="showTab(tab)" :id="`tab-${tab.name}`" :data-name="`${tab.name} section`">
                    <TabWorks v-if="tab.name == 'works'" :data="data" :id="id" />
                    <TabGoods v-if="tab.name == 'goods'" :data="data" :id="id" />
                    <TabContract v-if="tab.name == 'contract'" :data="data" :id="id" />
                    <TabReports v-if="tab.name == 'reports'" :data="data" :id="id" />
                    <TabFurnitureReports v-if="tab.name == 'furniture'" :data="data" :id="id" />
                    <TabDocs
                      v-if="'doc,doc_adv,doc_law,doc_furniture'.split(',').includes(tab.name)"
                      :data="data"
                      :category="showDocs(tab.name)"
                      @addDocs="addNewDocs(tab.name)"
                    />
                    <TabBalance v-if="tab.name == 'balance'" :data="{ data, id }" />
                  </v-tab-item>
                </v-tabs-items>
              </material-card>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item :id="`tabObjectView-stat`">
          <ViewPlanning v-if="true" :idShow="id" />
        </v-tab-item>
      </v-tabs-items>
      <div v-if="loading" class="loading-block">
        <div class="loader"></div>
      </div>
      <confirm-dialog ref="confirmDialog"></confirm-dialog>
      <popup-menu v-model="popumMenuShow" :items="popupMenuList" :position="popupMenuPosition" @click="onClickMenu($event)" />
    </div>
  </static-fullscreen-card>
</template>

<script>
import { statusChange, getAccess, autoHeightBlock, popupMenu, genModel } from "@/components/mixings";
export default {
  mixins: [getAccess, statusChange, autoHeightBlock, popupMenu, genModel],
  components: {
    AddDocDialog: () => import("./../dialogs/addDocumentDialog"),
    editDialog: () => import("./../dialogs/objectDialog"),
    StateSaleDialog: () => import("./../dialogs/stateSaleDialog"),
    TabReports: () => import("./tabs/tabObjectReports"),
    TabFurnitureReports: () => import("./tabs/tabFurnitureReports"),
    TabContract: () => import("./tabs/tabContract"),
    TabGoods: () => import("./tabs/tabObjectGoods"),
    TabWorks: () => import("./tabs/tabObjectWorks"),
    TabDocs: () => import("./tabs/tabDocs"),
    TabBalance: () => import("./tabs/tabObjectBalance"),
    ViewPlanning: () => import("./planning/planningForm"),
    TabGoodsSummary: () => import("./tabs/tabObjectGoodsSummary"),
  },
  props: {
    idShow: Number,
  },
  data() {
    return {
      id: 0,
      oldDoc: false,
      loading: true,
      loaded: false,
      showAddDocDialog: false,
      addDocModel: null,
      showStateSaleDialog: false,
      objectTypeView: "tabObjectView-main",
      showEditDialog: false,
      confirmDialog: false,
      title: "",
      data: {},
      m: this.$store.getters["config/get"].models.objects,
      model: this.$store.getters["config/get"].models.objects.form,
      mFinance: this.$store.getters["config/get"].models.finance,
      url: "/mechti/objects",
      tab: 0,
      tab_photo: 0,
      tab_info: 0,
      balance: { debit: 0, credit: 0 },
      clientHeight: 0,
      _progress: [],
      balanceTable: { data: null, debit: 0, credit: 0 },
    };
  },
  created() {
    this.loading = true;
    if (!this.idShow) this.$root.title = this.$route.meta.title || "Объекты";
    this.id = this.idShow || Number(this.$route.params.id);
    this.permit = this.getAccess("menu.objects");
    this.fitchData();
    if (this.$attrs?.type) {
      console.log("atttr type", this.$attrs.type);
      this.objectTypeView = this.$attrs.type;
    }
    if (this.$attrs?.tab) {
      this.tab = this.$attrs.tab;
    }
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.fitchData();
      }
    },
    showAddDocDialog(v) {
      if (!v) {
        this.fitchData();
      }
    },
  },
  computed: {
    objectProgress() {
      if (!this._progress.length) {
        this.getObjectProgress();
      }
      return this._progress;
    },
    modelRight() {
      let model = [];
      if (this.$root.profile.role == 10) {
        this.getObjectProgress();
        model = [
          ...this.calcModel("formInfo_role10"),
          { name: "data.investment_amount", title: "Сумма по договору", type: "number", showNull: true },
          { name: "balance_debit", value: (this.balanceData?.debit || 0).toLocaleString(), title: "Оплачено", type: "string", showNull: true },
          {
            name: "balance_credit",
            value: ((this.data?.data?.investment_amount || 0) - (this.balanceData?.debit || 0)).toLocaleString(),
            title: "К оплате",
            type: "string",
            showNull: true,
          },
          {
            name: "procress_1",
            title: "Комплектация",
            type: "string",
            calculated: true,
            formula: `((d) => {let p=d?._progress?.['Комплектация'] || null; return p ? p.per+'%' : null})`,
          },
          {
            name: "procress_2",
            title: "Дизайн",
            type: "string",
            calculated: true,
            formula: `((d) => {let p=d?._progress?.['Дизайнер'] || null; return p ? p.per+'%' : null})`,
          },
          {
            name: "procress_3",
            title: "Мебель",
            type: "string",
            calculated: true,
            formula: `((d) => {let p=d?._progress?.['Мебель'] || null; return p ? p.per+'%' : null})`,
          },
          {
            name: "procress_4",
            title: "Ремонт",
            type: "string",
            calculated: true,
            formula: `((d) => {let p=d?._progress?.['Стройка'] || null; return p ? p.per+'%' : null})`,
          },

          //, calculated: true, formula: `((d) => {return d.balanceCalc?.type_2 || null;})`
        ];
      }
      if (this.$root.profile.role > 20)
        model = [
          ...this.calcModel("formInfo"),
          { name: "object_men", value: this.getUsersByRole(60), title: "Менеджер проекта", type: "selectuser" },
          { name: "object_teh", value: this.getUsersByRole(50), title: "Технадзор", type: "selectuser" },
          { name: "object_design", value: this.getUsersByRole(55), title: "Дизайнер проекта", type: "selectuser" },
        ];
      this.fillData(model);
      return model;
    },
    oModel() {
      let model = [];
      this.model.forEach((el) => {
        model[el.name] = el;
      });
      return model;
    },
    infoTabs() {
      let tabs = [
        { name: "info", title: "Информация", show: true },
        { name: "finance", title: "Финансы", show: this.$root.profile.role > 20 },
        { name: "goods", title: "Чек-лист", show: this.$root.profile.role > 20 },
      ];
      return tabs;
    },
    tabs() {
      let tabs = [
        { name: "works", title: "График работ", icon: "mdi-arrow-expand", click: "goToWorks", show: true },
        { name: "goods", title: "Чек-лист", icon: "mdi-arrow-expand", click: "goToGoods", show: true },
        { name: "reports", title: "Фото отчеты", show: true },
        { name: "contract", title: "Договоры", show: this.$root.profile.role > 20 },
        { name: "furniture", title: "Работа-отчет", show: this.$root.profile.role > 20 },
        { name: "doc", title: "Проект", show: true },
        { name: "doc_adv", title: "Реклама", show: true },
        { name: "doc_law", title: "Юридические документы", show: true },
        { name: "doc_furniture", title: "Мебель", show: true },
        { name: "balance", title: "Баланс", show: true },
      ];
      return tabs;
    },
    balanceData() {
      if (!this.balanceTable.data) this.getBalanceTable();
      return this.balanceTable;
    },
    modelFinance() {
      let model = JSON.parse(JSON.stringify(this.mFinance.list));
      model.forEach((el) => {
        if (el?.sourceList) {
          el.options = this.mFinance[el.sourceList];
        }
      });
      return model;
    },
    balanceText() {
      const balance = this.balanceData && this.balanceData.debit !== undefined ? this.balanceData : this.balance;
      if (!balance.debit && !balance.credit) return "нет данных";
      return (balance.debit || 0).toLocaleString() + " - " + (balance.credit || 0).toLocaleString() + " = " + Number(balance.debit - balance.credit).toLocaleString();
    },
    investmentText() {
      //console.log("investmentText", this.balanceData.debit, this.balance);
      const balance = this.balanceData && this.balanceData.debit ? this.balanceData : this.balance;
      const investment = this.data.data?.investment_amount ? Number(this.data.data.investment_amount).toLocaleString() : "???";
      if (!balance.debit && !investment) return "нет данных";
      return (balance.debit !== null ? balance.debit.toLocaleString() : "???") + " из " + investment;
    },

    object_employees() {
      let a = this.data.object_access ? this.data.object_access.filter((el) => el.user_role < 100) : [];
      return a;
    },
    editObject() {
      return this.getAccess("object.edit", {
        id: this.id,
        access: this.data.object_access,
        data: this.data,
      });
    },
    showObject() {
      return (
        this.getAccess("object.edit", {
          id: this.id,
          access: this.data.object_access,
          data: this.data,
          inRole: -1,
        }) || this.getAccess("object.view")
      );
    },
    statusObject() {
      //console.log(this.$root.profile);
      return this.getAccess("object.status", {});
    },
    editObjectGoods() {
      //console.log(this.$root.profile);
      const access = this.data.object_access || [];
      return this.getAccess("object.edit", {
        access: access.filter((a) => a.user_role == 55),
      });
    },
    isTypeFurniture() {
      return this.data.object_type & (1 << (3 - 1));
    },
    isTypeWork() {
      return this.data.object_type & (1 << (2 - 1));
    },
    isTypeDesign() {
      return this.data.object_type & (1 << (1 - 1));
    },
  },
  methods: {
    fillData(model) {
      for (let el of model) {
        if (el?.calculated == true) {
          if (el?.formula) {
            let f = el.formula;
            if (!this.data.hasOwnProperty(el.name))
              Object.defineProperty(this.data, el.name, {
                get: function () {
                  let res = 0;
                  let func;
                  if (typeof f == "function") func = f;
                  if (typeof f == "string") func = eval(f);
                  res = func(this);
                  return res;
                },
              });
          }
        }
      }
    },
    async getObjectProgress() {
      let params = { id: this.id };
      let resp = await this.$axios.post("/report_sql", { name: "getObjectProgress", params });

      let d = resp?.data?.data || [];
      this._progress = [...(resp?.data?.data || [])];
      let p = {};
      d.forEach((r) => (p[r.name] = { per: r.per, t: r.total }));
      this.data._progress = p;
      console.log(p);
    },
    getUsersByRole(r) {
      let a = this.data.object_access ? this.data.object_access.filter((el) => el.user_role == r && el.user_status == 1) : [];
      return a;
    },
    showTab({ name, show }) {
      let res = true;
      if (!show) return show;
      if (name.includes("doc_") || name == "doc") {
        res = this.getAccess(`object.doc.${name}`, null, true);
      }
      if (name == "balance") return this.getAccess("object.showBalance");
      if (name == "doc_furniture") return res && this.isTypeFurniture;
      if (name == "goods") return this.isTypeWork || this.isTypeFurniture;
      if (name == "reports") return this.isTypeWork;
      if (name == "doc_adv") return res && this.isTypeWork && this.isTypeFurniture && this.isTypeDesign;
      return res;
    },
    showDocs(field) {
      return this.model.find((el) => el.name == field)?.category || -1;
    },
    addNewDocs(field) {
      this.addDocModel = this.model.find((el) => el.name == field) || null;
      if (!this.addDocModel) return;
      this.showAddDocDialog = true;
    },
    changeTab(tab, type = "tab") {
      if (!this.idShow) this.$router.push({ query: { [type]: tab } });
    },
    async fitchData() {
      try {
        const d = await this.$axios.get(this.url + "/" + this.id);
        if (d.data && d.data.data) {
          /*       for (const name in d.data.data) {
          if (name == "data" && this.data?.data && !d.data.data[name]) {
            for (const nameData in d.data.data.data) {
              this.$set(this.data.data, nameData, d.data.data.data[nameData]);
            }
          } else this.$set(this.data, name, d.data.data[name]);
        } */
          this.data = d.data.data;
        }
      } catch (error) {
        this.$root.$emit("show-info", {
          text: "Данные не доступны.",
          type: "error",
        });
        this.loading = false;
        return;
      }

      // await this.getBalance();
      this.loading = false;
      this.loaded = !false;
    },
    async getBalanceTable() {
      let res = [];
      let q = `
select id, credit_account 'account',
  date_doc 'date', operation_id,
	credit_subconto_value_1 'name1',
	credit_subconto_value_2 'name2',
	credit_subconto_value_3 'name3',
	value 'value_cr', null 'value_db'
from accounting_record
where (credit_account ="76.1"	and credit_subconto_value_2=${this.id})
      or (credit_account = "62.2"	and credit_subconto_value_1=${this.id})
union all
select id, credit_account 'account',
  date_doc 'date', operation_id,
	credit_subconto_value_1 'name1',
	credit_subconto_value_2 'name2',
	credit_subconto_value_3 'name3',
	-value 'value_cr', null 'value_db'
from accounting_record
where  (debit_account = "62.2"	and debit_subconto_value_1=${this.id})
union all
select id, debit_account 'account',
  date_doc 'date', operation_id,
	debit_subconto_value_1 'name1',
	debit_subconto_value_2 'name2',
	debit_subconto_value_3 'name3',
	null 'value_cr', value 'value_db'
from accounting_record
where debit_account like "08%"
	and debit_subconto_value_1=${this.id}`;
      let resp = await this.$axios.post("/report_sql", { name: "getObjectBalance", params: { id: this.id } });

      //      let resp = await this.$axios.post("accounting/records/report-sql", { q });

      let debit = 0;
      let credit = 0;
      if (resp.data.status == "ok") {
        res = resp.data.data;
        //  console.log("get balance table", res);
        resp.data.data.forEach((el) => {
          if ([35, 36, 48].includes(el.operation_id)) {
          } else {
            //console.log(el.operation_id, el);
            debit += el.value_cr;
            credit += el.value_db;
          }
        });
      }
      this.balanceTable = { data: res, debit, credit };
    },

    updateData() {
      this.fitchData();
    },
    clickTabIcon(t) {
      this[t.click]();
    },
    goToGoods() {
      this.$router.push({ name: "objects_goods", params: { id: this.id } });
    },
    goToWorks() {
      this.$router.push({ name: "objects_works", params: { id: this.id } });
    },
    async getBalance(filter = { object_id: this.id }) {
      const response = await this.$axios.get("/mechti/finance/balance", {
        params: { filters: filter },
      });
      this.balance = response.data.data;
    },
  },
};
</script>

<style lang="sass">
.v-slide-group1__prev--disabled
  display: none !important
.v-slide-group1__next--disabled
  display: none !important
</style>
